/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { faHandshakeSlash, faPhone, faPhoneSlash, faShare, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import ActionButton from './ActionButton';

function CallWindow({ peerSrc, localSrc, config, pc, mediaDevice, status, endCall }) {
  const peerVideo = useRef(null);
  const localVideo = useRef(null);
  const [video, setVideo] = useState(config.video);
  const [audio, setAudio] = useState(config.audio);
  const [isShareScreen, setIsShareScreen] = useState(false)

  useEffect(() => {
    if (peerVideo.current && peerSrc) peerVideo.current.srcObject = peerSrc;
    if (localVideo.current && localSrc) localVideo.current.srcObject = localSrc;
  });

  useEffect(() => {
    if (mediaDevice) {
      mediaDevice.toggle('Video', video);
      mediaDevice.toggle('Audio', audio);
    }
    console.log(config)
  },[mediaDevice]);

  /**
   * Turn on/off a media device
   * @param {'Audio' | 'Video'} deviceType - Type of the device eg: Video, Audio
   */
  const toggleMediaDevice = (deviceType) => {
    if (deviceType === 'Video') {
      setVideo(vid => !vid);
      mediaDevice.toggle(deviceType, !video);
    }
    if (deviceType === 'Audio') {
      setAudio(audio => !audio);
      mediaDevice.toggle(deviceType, !audio);
    }
  };

  const toggleShare = () => {
    mediaDevice.toggleScreenShare(!isShareScreen,pc);
    setIsShareScreen(prev => !prev)
  }

  return (
    <>
    <div id="videoContainer" className="video-container">
        {/* <div class="icon-container">
          <button class="icon-back">
            <i id="minimizeIcon" class="fa fa-window-minimize"></i>
          </button>
          <button class="icon-back">
            <i id="dragIcon" class="fa fa-arrows-alt"></i>
          </button>
        </div> */}
        <div className="video-wraper">
          <div className="small-video" id="small-vid">
            <video ref={localVideo} autoPlay muted> </video>
          </div>
          <div className="big-video">
            <video ref={peerVideo} autoPlay> </video>
          </div>
          <div className="controls">
            <ActionButton
              iconClass="blue-back"
              className='icon-back'
              key="btnVideo"
              icon={video ? faVideo: faVideoSlash}
              onClick={() => toggleMediaDevice('Video')}
            />
            <ActionButton
              iconClass="blue-back"
              className='icon-back'
              key="btnAudio"
              icon={audio ? faPhone: faPhoneSlash}
              onClick={() => toggleMediaDevice('Audio')}
            />
            <ActionButton
              iconClass="blue-back"
              className='icon-back'
              key="btnShare"
              icon={!isShareScreen ? faShare: faHandshakeSlash}
              onClick={() => toggleShare()}
            />
            <ActionButton
              className="hangup icon-back red"
              icon={faPhone}
              onClick={() => endCall(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

CallWindow.propTypes = {
  status: PropTypes.string.isRequired,
  localSrc: PropTypes.object,
  peerSrc: PropTypes.object,
  config: PropTypes.shape({
    audio: PropTypes.bool.isRequired,
    video: PropTypes.bool.isRequired
  }).isRequired,
  mediaDevice: PropTypes.object,
  endCall: PropTypes.func.isRequired
};

export default CallWindow;