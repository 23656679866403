/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// reactstrap components
import {
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col, DropdownMenu, DropdownItem,
} from "reactstrap";
import { hasAccess } from "variables/utils.js";

var ps;
import Payment from "components/Payment";
import { withTranslation } from "react-i18next";
import translationKeys from "./TranslationKey";
import UserUpdateModal from "../Modals/UserUpdateModal";
import i18n from "../../translations/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import '../../views/examples/styles.css'
class Sidebar extends React.Component {
    state = {
        collapseOpen: false,
        ePaymentOpen: false,
        updateProfile: false
    };

    constructor(props) {
        super(props);
        this.activeRoute.bind(this);
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    // toggles collapse between opened and closed (true/false)
    toggleCollapse = () => {
        this.setState({
            collapseOpen: !this.state.collapseOpen,
        });
    };
    // closes the collapse
    closeCollapse = () => {
        this.setState({
            collapseOpen: false,
        });
    };
    toggleModal = (state) => {
        this.setState({
            updateProfile: !this.state.updateProfile,
            // ePaymentOpen: !this.state.ePaymentOpen,
        });
    };
    // creates the links that appear in the left menu / Sidebar
    createLinks = (routes) => {
        const { t } = this.props; // Destructure the t function from props

        if (localStorage.getItem("user")) {
            let userType = JSON.parse(localStorage.getItem("user")).user_type;
            let refer = JSON.parse(localStorage.getItem("user")).referred_count;
            let paid = JSON.parse(localStorage.getItem("paid"));
            return routes.map((prop, key) => {
                if (hasAccess(prop.user, userType) && `${prop.layout}${prop.path}` !== '/admin/call') {
                    return (
                        <NavItem key={key}>
                            {prop.name !== 'Rewards' && prop.name !== 'Esewa' && prop.name !== 'Khalti' ?
                                <>
                                    {refer > 0 && prop.name === 'ReferAndEarn' ?
                                        <NavLink
                                            to={prop.layout + prop.path}
                                            tag={NavLinkRRD}
                                            onClick={this.closeCollapse}
                                            activeClassName="active"
                                        >
                                            {/*<img height={30} width={30} src={prop.img} />*/}
                                            <i className={prop.icon} />
                                            &nbsp;{t(translationKeys[prop.name])}
                                        </NavLink> :
                                        <>
                                            {prop.name === 'TeacherSupport' ?
                                                <NavLink
                                                    to={prop.layout + prop.path}
                                                    tag={NavLinkRRD}
                                                    onClick={this.closeCollapse}
                                                    activeClassName="active"
                                                  style={{
                                                    width:'max-content'
                                                  }}
                                                >
                                                    {/*<img height={30} width={30} src={prop.img} />*/}
                                                    <i className={prop.icon} />
                                                    &nbsp;{t(translationKeys[prop.name])}
                                                </NavLink>
                                                :
                                                <>
                                                    {prop.name !== 'ReferAndEarn' && prop.name !== 'TeacherSupport' ?
                                                        <NavLink
                                                            to={prop.layout + prop.path}
                                                            tag={NavLinkRRD}
                                                            onClick={this.closeCollapse}
                                                            activeClassName="active"
                                                           
                                                        >
                                                            {/*<img height={30} width={30} src={prop.img} />*/}
                                                            <i className={prop.icon} />
                                                            &nbsp;{t(translationKeys[prop.name])}
                                                            
                                                        </NavLink>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                            }
                                        </>
                                    }

                                </>

                                :
                                <></>
                            }
                        </NavItem>
                    );
                }
            });
        } else {
            this.props.history.push("/auth/login");
        }
    };

    logoutUser = async () => {
        await localStorage.clear();
        this.props.history.push('/auth/login')
    }

    changeLanguage = async (lng) => {
        await i18n.changeLanguage(lng);
    }

    render() {
        const { bgColor, routes, logo } = this.props;
        let base = window.config.imgUrl
        let navbarBrandProps;
        if (logo && logo.innerLink) {
            navbarBrandProps = {
                to: logo.innerLink,
                tag: Link,
            };
        } else if (logo && logo.outterLink) {
            navbarBrandProps = {
                href: logo.outterLink,
                target: "_blank",
            };
        }
        return (
            <>
                <Navbar
                    className={`navbar-vertical  ${this.props.sidebartoggle ? '' : 'd-none'}`}
                    expand='md'
                    style={{
                        position: 'relative',
                    //   borderRight:'1px solid black'
                        // backgroundColor:'#4f2bab',
                    }}
                    id="sidenav-main"
                >
                    <Container fluid>
                        {/* Toggler */}
                        <div className="sidebar_control" style={{
                           
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100vw',
                            gap: '2px',
                            justifyContent: 'space-between'
                        }}>
                            <button
                                className="d-md-none toggle-btn-nav "
                                type="button"
                                onClick={this.toggleCollapse}
                            >
                                <FontAwesomeIcon icon={faBars} size="xl" />
                            </button>
                            {/* Brand */}
                            {logo ? (
                                <NavbarBrand className="pt-0" {...navbarBrandProps}>
                                    {/* web */}
                                    <img
                                        alt={logo.imgAlt}
                                        className="navbar-brand-img"
                                        src={logo.imgSrc}
                                    />
                                    {/* mobile */}
                                      <img src="https://englishhub.app/englishhublogo.png" 
                                      alt="English Hub Logo"
                                       className="navbar-brand-img-mobile" />
                                </NavbarBrand>
                            ) : null}
                            {/* User */}
                            <Nav className={`align-items-center d-md-none `}>
                                <img style={{ cursor: 'pointer' }} src={require('../../assets/img/eng.png')} height={20}
                                    width={20} onClick={() => this.changeLanguage('eng')} />&nbsp;&nbsp;
                                <div style={{ borderRight: '2px dotted white', height: '12px' }}></div>
                                &nbsp;
                                <img style={{ cursor: 'pointer' }} src={require('../../assets/img/np.png')} height={20}
                                    width={20} onClick={() => this.changeLanguage('np')} />
                                <UncontrolledDropdown nav>
                                    <DropdownToggle className="pr-0" nav>
                                        <Media className="align-items-center">
                                            <img className="avatar avatar-sm rounded-circle"
                                                height={80}
                                                width={80}
                                                alt="..."
                                                src={
                                                    JSON.parse(localStorage.getItem('user'))?.profile_picture !== null && JSON.parse(localStorage.getItem('user'))?.profile_picture?.[0] === '/'
                                                        ? base.concat(JSON.parse(localStorage.getItem('user'))?.profile_picture) :
                                                        JSON.parse(localStorage.getItem('user'))?.profile_picture !== null && JSON.parse(localStorage.getItem('user'))?.profile_picture?.[0] === 'h'
                                                            ? JSON.parse(localStorage.getItem('user'))?.profile_picture :
                                                            JSON.parse(localStorage.getItem('user'))?.profile_picture !== null ?
                                                                base1.concat(JSON.parse(localStorage.getItem('user'))?.profile_picture)
                                                                : require("assets/img/theme/avatarnew.png")
                                                }
                                            />
                                            <Media className="ml-2 d-none d-lg-block">
                                                <span className="mb-0 text-sm font-weight-bold">
                                                    {JSON.parse(localStorage.getItem('user'))?.full_name}
                                                </span>
                                            </Media>
                                        </Media>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem onClick={() => this.setState({ updateProfile: true })}>
                                            <span>Update Profile</span>
                                        </DropdownItem>
                                        <DropdownItem onClick={() => this.logoutUser()}>
                                            <span>Logout</span>
                                        </DropdownItem>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </Nav>
                        </div>
                        {/* Collapse */}
                        <Collapse navbar isOpen={this.state.collapseOpen}>
                            {/* Collapse header */}
                            <div className="navbar-collapse-header d-md-none ">
                                <Row>
                                    {logo ? (
                                        <Col className="collapse-brand" xs="6">
                                            {logo.innerLink ? (
                                                <Link to={logo.innerLink}>
                                                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                                                </Link>
                                            ) : (
                                                <a href={logo.outterLink}>
                                                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                                                </a>
                                            )}
                                        </Col>
                                    ) : null}
                                    <Col className="collapse-close" xs="6">
                                        <button
                                            className="navbar-toggler"
                                            type="button"
                                            onClick={this.toggleCollapse}
                                        >
                                            <span />
                                            <span />
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav navbar>{this.createLinks(routes)}</Nav>

                        </Collapse>
                    </Container>
                </Navbar>
                {this.state.ePaymentOpen && (
                    <Payment
                        isOpen={this.state.ePaymentOpen}
                        toggle={this.toggleModal}
                    ></Payment>
                )}

                {this.state.updateProfile && (
                    <UserUpdateModal
                        updateProfile={this.state.updateProfile}
                        toggleModal={this.toggleModal}
                        type={'Header'}
                    />
                )}
            </>
        );
    }
}

Sidebar.defaultProps = {
    routes: [{}],
};

Sidebar.propTypes = {
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the image src of the logo
        imgSrc: PropTypes.string.isRequired,
        // the alt for the img
        imgAlt: PropTypes.string.isRequired,
    }),
};

export default withTranslation()(Sidebar);
