import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { faPhone, faVideo } from '@fortawesome/free-solid-svg-icons';
import ActionButton from './ActionButton';
import { SocketContext } from 'layouts/SocketContext';
import { socketListen } from '../../utils/video-call/SocketListener'
import MediaDevice from '../../utils/video-call/MediaDevice';
let clientId = 102901

function MainWindow({ startCall }) {
  const testvid = useRef(null);
  const mediaStreamRef = useRef(null);

  // useEffect(() => {
  //    new MediaDevice().on('stream', (stream) => {
  //     testvid.current.srcObject = stream
  //     mediaStreamRef.current = stream
  //    }).start()
  //    return () => {
  //     if (mediaStreamRef.current) {
  //       mediaStreamRef.current.getTracks().forEach(track => {
  //         track.stop();
  //       });
  //     }
  //   };
  // }, [])

  /**
   * Start the call with or without video
   * @param {Boolean} video
   */
  const callWithVideo = (video) => {
    const config = { audio: true, video };
    return () => startCall(true, null, config);
  };

  return (
    <div className="container main-window">
      <div>
        <h3>
          Hi, Start Calling here to Admin
        </h3>
        <h4>Get started by calling a friend below</h4>
      </div>
      {/* <div>
         <video style={{width:'400px', height:'300px'}} ref={testvid} playsInline autoPlay/>
      </div> */}
      <div>
        <div>
          <ActionButton className='icon-back-big' icon={faVideo} onClick={callWithVideo(true)} />
          <ActionButton className='icon-back-big' icon={faPhone} onClick={callWithVideo(false)} />
        </div>
      </div>
    </div>
  );
}

MainWindow.propTypes = {
  startCall: PropTypes.func.isRequired
};

export default MainWindow;